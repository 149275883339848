import { render, staticRenderFns } from "./LegendItem.vue?vue&type=template&id=0f8cb6b0"
import script from "./LegendItem.vue?vue&type=script&setup=true&lang=ts"
export * from "./LegendItem.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./LegendItem.vue?vue&type=style&index=0&id=0f8cb6b0&prod&module=true&lang=css"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Copy: require('/app/node_modules/@estaid/ui/dist/runtime/atoms/Copy/Copy.vue').default,Label: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/Label/Label.vue').default,KeyValuePair: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/KeyValuePair/KeyValuePair.vue').default})
